<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <app-card-code
        :title="`${$t('menu.moderations')}`"
      >
        <Moderations></Moderations>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'

import Moderations from './form/Moderations.vue'

export default {
  components: {
    AppCardCode,
    Moderations,
  },
}
</script>
